import { SetUpSlideScroll } from "./slideScroll";

function isParentOf(child: Node, parent: Element) {
    if (child.parentNode === null) return false;
    if (child.parentNode === parent) return true;
    return isParentOf(child.parentNode, parent);
}

function SetUpBurgerMenu() {
    const elements = Array.from(document.getElementsByClassName("expandable"));
    const action = document.getElementById("burgerMenuAction");
    const scrim = document.getElementById("burgerScrim");
    const menu = document.getElementById("burgerMenu");
    if (action) {
        action.addEventListener("click", e => {
            e.stopPropagation();
            e.preventDefault();
            document.body.classList.toggle("burgerMenuOpen");
        })
    }
    if (scrim) {
        scrim.addEventListener("click", e => {
            e.stopPropagation();
            document.body.classList.toggle("burgerMenuOpen");
        })
    }
    if (menu) {
        menu.addEventListener("click", e => {
            e.stopPropagation();
        })
    }
    for (const element of elements) {
        element.addEventListener("click", e => {
            e.stopPropagation();
            if (e.target instanceof Element && e.target.tagName === "A") {
                //link clicked, close menu and skip out 
                document.body.classList.toggle("burgerMenuOpen");
                return
            }
            //collapse all elements except this element
            for (const innerElement of elements) {
                if (innerElement !== element && !isParentOf(element, innerElement)) {
                    innerElement.classList.remove("expanded");
                }
            };
            element.classList.toggle("expanded");
        });
    }
}

function Bootstrap() {
    document.addEventListener("readystatechange", () => {
        if (document.readyState === "interactive") {
            SetUpBurgerMenu();
            SetUpSlideScroll();
        }
    });
}

Bootstrap();