const GAP = 16;     // Gap between elements in the flex container
const BASE_CLASS = "slideScroll";

// Todo: Refactor before using in new site. Update old soho site if there are
// meaningful improvements.

export const SetUpSlideScroll = (initialSnap?: number) => {
    const containers = Array.from(document.querySelectorAll(`.${BASE_CLASS}_container`));
    for (const container of containers) {

        if (container instanceof HTMLElement) {
            console.log("$RTSS", container);

            const track = document.querySelector(`.${BASE_CLASS}_track`) as HTMLElement;
            const cards = Array.from(document.querySelectorAll(`.${BASE_CLASS}_card`)) as Array<HTMLElement>;

            let active = false;									// Currently scrolling the view?
            let transform = 0;									// Current transform so we don't have to parse styles too much
            let wheelTimeout: any;						    // Reference to wheel timeout
            let touchMovePrevX: number;					        // No delta on touchmove so have to track it ourselves

            const getCurrentTrackTransform = () => {
                const transformMatch = track.style.transform.match(/-?\d+(\.\d+)?/);
                const transform = transformMatch ? transformMatch[0] : "0";
                return parseInt(transform, 10);
            }
            const getClosestCardToCentre = () => {
                const containerCentre = container.offsetWidth / 2;
                const transformedCenter = (getCurrentTrackTransform() * -1) + containerCentre;
                const cardWidth = (cards.at(0)?.offsetWidth || 0) + GAP;
                const index = Math.round((transformedCenter - cardWidth / 2) / cardWidth);
                const clamped = Math.min(cards.length - 1, Math.max(0, index));

                return clamped;
            }
            const getCardTransformOffset = (idx: number) => {
                const containerCentre = container.offsetWidth / 2;
                const cardWidth = cards.at(0)?.offsetWidth || 0;
                const cumulativeGap = Math.max(GAP * idx, 0);
                const cardOffset = cardWidth * idx + cardWidth / 2;
                const transform = cardOffset - containerCentre + cumulativeGap;

                return transform * -1;
            }
            const snap = (idx?: number) => {
                const closest = idx === undefined ? getClosestCardToCentre() : idx;
                const transform = getCardTransformOffset(closest);
                track.style.transform = `translateX(${transform}px)`;
            }

            const handleStartInteraction = (e: TouchEvent) => {
                active = true;
                container.classList.add(`${BASE_CLASS}_container-active`);
                container.style.cursor = 'grabbing';
                transform = getCurrentTrackTransform();
            }
            const handleMouseMove = (e: MouseEvent) => {
                if (!active) return;
                e.preventDefault();
                transform += e.movementX;
                track.style.transform = `translateX(${transform}px)`;
            }
            const handleWheel = (e: WheelEvent) => {
                //Prevent default if we intentionally scrolling...
                if (Math.abs(e.deltaX) > Math.abs(e.deltaY / 2))
                    e.preventDefault();

                clearTimeout(wheelTimeout);

                // First time around set up
                if (!active) {
                    active = true;
                    transform = getCurrentTrackTransform();
                    container.classList.add(`${BASE_CLASS}_container-active`);
                }

                // Do the transform
                transform -= e.deltaX;
                track.style.transform = `translateX(${transform}px)`;

                // Cleanup and snap when finished
                wheelTimeout = window.setTimeout(() => {
                    wheelTimeout = null;
                    active = false;
                    container.classList.remove(`${BASE_CLASS}_container-active`);
                    snap();
                }, 250);
            }
            const handleTouchMove = (e: TouchEvent) => {
                if (!active) return;
                e.preventDefault();
                if (e.touches[0]) {
                    const distance = touchMovePrevX ? e.touches[0].pageX - touchMovePrevX : 0;
                    touchMovePrevX = e.touches[0].pageX;
                    transform += distance;
                    track.style.transform = `translateX(${transform}px)`;
                }
            }
            const handleEndInteraction = (e: TouchEvent) => {
                active = false;
                container.classList.remove(`${BASE_CLASS}_container-active`);
                container.style.cursor = 'grab';
                touchMovePrevX = 0;
                snap();
            }

            const attachListeners = () => {
                container.addEventListener('mousedown', handleStartInteraction);
                container.addEventListener('mouseleave', handleEndInteraction);
                container.addEventListener('mouseup', handleEndInteraction);
                container.addEventListener('mousemove', handleMouseMove);
                container.addEventListener('wheel', handleWheel);
                container.addEventListener('touchstart', handleStartInteraction);
                container.addEventListener('touchmove', handleTouchMove);
                container.addEventListener('touchend', handleEndInteraction);
            }
            const removeListeners = () => {
                container.removeEventListener('mousedown', handleStartInteraction);
                container.removeEventListener('mouseleave', handleEndInteraction);
                container.removeEventListener('mouseup', handleEndInteraction);
                container.removeEventListener('mousemove', handleMouseMove);
                container.removeEventListener('wheel', handleWheel);
                container.removeEventListener('touchstart', handleStartInteraction);
                container.removeEventListener('touchmove', handleTouchMove);
                container.removeEventListener('touchend', handleEndInteraction);
            }
            const init = () => {
                if (container.offsetWidth < track.scrollWidth) {
                    container.classList.add(`${BASE_CLASS}_container-enabled`);
                    attachListeners();
                    snap(initialSnap);
                } else {
                    container.classList.remove(`${BASE_CLASS}_container-enabled`)
                    track.style.transform = "initial";
                    container.style.cursor = "initial";
                    removeListeners();
                }
            }

            window.addEventListener("resize", init);
            init();
        }
    }
}